/* Arquivo de configuração de Estilo do Menu Lateral */
ul li, a {
    text-decoration: none;
    list-style: none;
}
.container_nav {
    z-index: 101;
    display: flex;
    padding-left: 10px;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0px;
    padding-top: 10px;
}
.container_btn_menu {
    width: 100%;
    display: flex;
    justify-content: start;
    text-align: start;
}
#btn_menu {
    border: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 8px;
    padding: 0px 8px;
    transition: all .2s ease-in-out;
}
#btn_menu:hover {
    background-color: #DDE3EC;
    transition: all .2s ease-in-out;
}
.icon_btn_menu {
    margin: 0 auto;
    color: #343434;
    font-size: 26px;
}
.container_link_menu {
    margin-top: 16px;
    background-color: #1A4783;
    height: 100%;
    border-radius: 10px;
    padding: 8px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: width 0.3s ease;
    position: relative;
}
.container_link_menu li p {
    transition: opacity 0.3s ease, transform 0.3s ease;
}
.container_link_menu_admin {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    
}

.links {
    width: 100%;
    padding: 4px 10px;
    border-radius: 6px;
    transition: padding 0.3s ease;
    
}
.links.admin {
    display: flex;
    flex-direction: column;
}
.links:hover {
    transition: all .2s ease-in-out;
    background-color: #0055c5;
}
.link_nav {
    display: flex;
    align-items: center;
    gap: 12px;
    color: white;
    position: relative;
}
.link_nav.admin {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.icons_nav {
    font-size: 20px;
}
.icons_nav_arrow {
    font-size: 16px;
}
.nome_link {
    font-size: 14px;
}
.container_link_adm {
    display: flex;
    align-items: center;
    gap: 12px;
}
/* Modificar o Scroll  */

/* Trilho do Scroll */
::-webkit-scrollbar {
    width: 2px;
}
/* Trem Sroll */
::-webkit-scrollbar-thumb {
   background-color: #ffffff;
}

/* Card Suporte */
.container_suporte {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    padding: 8px;
    gap: 6px;
    margin-top: 2px;
}
.header_suporte {
    display: flex;
    align-items: center;
    gap: 14px;
}
.p_header_suporte {
    font-size: 14px;
    padding: 0;
    margin: 0;
}
.p_suporte {
    font-size: 12px;
    text-align: center;
    padding: 0;
    margin: 0;
}
.btn_contato {
    padding: 8px 14px;
    transition: all .2s ease-in-out;
    border-radius: 6px;
    background-color: #1A4783;
    color: white;
}
.btn_contato:hover {
    transition: all .2s ease-in-out;
    background-color: #0055c5;
}