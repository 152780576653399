*{
  font-family: "Bai Jamjuree", sans-serif;
}

.boas-vindas {
  /* margin-top: 40px; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 10px;
}

.line {
  background-color: #343434;
  width: 30px;
  height: 2px;
}

.bg {
    animation:slide 3s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, #1a4783 50%, #09f 50%);
    bottom:0;
    left:-50%;
    opacity:.5;
    position:fixed;
    right:-50%;
    top:0;
    z-index:-1;
  }
  
  .bg2 {
    animation-direction:alternate-reverse;
    animation-duration:8s;
  }
  
  .bg3 {
    animation-duration:5s;
  }
  
  @keyframes slide {
    0% {
      transform:translateX(-25%);
    }
    100% {
      transform:translateX(25%);
    }
  }
  